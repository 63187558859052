import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "components/layout"
import * as styles from "styles/post.module.css"
import * as styles_detail from "styles/content-detail.module.css"
import Meta from "components/meta"

const remark =  ({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title,date,tag },
    },
  },
}) => {


  return (
    <Layout>
      <Meta
      title={title}
      />

      <div className={styles.article}>
      <h1>{title}</h1>
      <div className={styles.content}>
      <span className={styles_detail.content_date}>Posted on </span>{date}
      </div>
      <div className={styles.content}>
        <span className={styles_detail.content_tag}>Tag : </span>
        <ul className={styles_detail.list}>
        {tag.map(tag =>(
           <li key={tag} className={styles_detail.item}>
           <Link to={`/works/tag/${tag}` } >{tag}</Link>
           </li>
        )
        )}
        </ul>
        </div>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
 
}



export default remark;

export const query = graphql`
query(
        $slug: String!
  ) {

  markdownRemark(fields: { slug: { eq: $slug } }) {
    id
     html
     frontmatter {
       title
       date (formatString: "YYYY/MM/DD(ddd)")
       tag
      }
    }
  

  }`

  /*   $previousPostSlug: String
  $nextPostSlug: String */



/*     previous: markdownRemark(fields: { slug: { eq: $previousPostSlug } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(fields: { slug: { eq: $nextPostSlug } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    } */